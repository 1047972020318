import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import C from '../assets/C.png';
import gallery01 from '../assets/gallery01.jpg';
import gallery02 from '../assets/gallery02.jpg';
import gallery03 from '../assets/gallery03.jpg';
import gallery04 from '../assets/gallery04.jpg';
import knife from '../assets/knife.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.png';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import chairman from '../assets/chairman.png';

import mainbg from '../assets/mainbg.png';
import nightbg from '../assets/nightbg.png';

// Menu Page 

import espatada from '../assets/espatadaRump.jpg';
import lambShank from '../assets/lambShank.jpg';
import lambRib from '../assets/lambRib.jpg';
import oxTail from '../assets/oxTail.jpg';
import filletOnTheBone from '../assets/filletOnTheBone.jpg';
import sashimi9ps from '../assets/slider-img/OurGallery/sushi/sashimi 9pcs.jpg';
import sushiSashimi from '../assets/slider-img/OurGallery/sushi/sushiSashimiFushion.jpg';
import spicyTunaTemaki from '../assets/slider-img/OurGallery/sushi/spicyTunaHandRoll.jpg';
import chickenWings from '../assets/chicken-wings.jpg';
import sushiPlatter from '../assets/sushi-platter.jpg';
import salmonCali from '../assets/slider-img/OurGallery/sushi/salmonCaliforniaRoll.jpg';
import deepFriedCali from '../assets/slider-img/OurGallery/sushi/deepFriedCalie.jpg';

// Gallery Page - bevs

// Gallery Page - gallry

import gallery1 from '../assets/slider-img/OurGallery/gallery/gallery (1).jpg';
import gallery2 from '../assets/slider-img/OurGallery/gallery/gallery (2).jpg';
import gallery3 from '../assets/slider-img/OurGallery/gallery/gallery (3).jpg';
import gallery4 from '../assets/slider-img/OurGallery/gallery/gallery (4).jpg';
import gallery5 from '../assets/slider-img/OurGallery/gallery/gallery (5).jpg';
import gallery6 from '../assets/slider-img/OurGallery/gallery/gallery (6).jpg';
import gallery7 from '../assets/slider-img/OurGallery/gallery/gallery (7).jpg';
import gallery8 from '../assets/slider-img/OurGallery/gallery/gallery (8).jpg';
import gallery9 from '../assets/slider-img/OurGallery/gallery/gallery (9).jpg';
import gallery10 from '../assets/slider-img/OurGallery/gallery/gallery (10).jpg';

// Gallery Page - meal

import meal1 from '../assets/slider-img/OurGallery/meal/meal (1).jpg';
import meal2 from '../assets/slider-img/OurGallery/meal/meal (2).jpg';
import meal3 from '../assets/slider-img/OurGallery/meal/meal (3).jpg';
import meal4 from '../assets/slider-img/OurGallery/meal/meal (4).jpg';
import meal5 from '../assets/slider-img/OurGallery/meal/meal (5).jpg';
import meal6 from '../assets/slider-img/OurGallery/meal/meal (6).jpg';
import meal7 from '../assets/slider-img/OurGallery/meal/meal (7).jpg';
import meal8 from '../assets/slider-img/OurGallery/meal/meal (8).jpg';
import meal9 from '../assets/slider-img/OurGallery/meal/meal (9).jpg';
import meal10 from '../assets/slider-img/OurGallery/meal/meal (10).jpg';
import meal11 from '../assets/slider-img/OurGallery/meal/meal (11).jpg';
import meal12 from '../assets/slider-img/OurGallery/meal/meal (12).jpg';
import meal13 from '../assets/slider-img/OurGallery/meal/meal (13).jpg';
import meal14 from '../assets/slider-img/OurGallery/meal/meal (14).jpg';
import meal15 from '../assets/slider-img/OurGallery/meal/meal (15).jpg';
import meal16 from '../assets/slider-img/OurGallery/meal/meal (16).jpg';
import meal17 from '../assets/slider-img/OurGallery/meal/meal (17).jpg';
import meal18 from '../assets/slider-img/OurGallery/meal/meal (18).jpg';
import meal19 from '../assets/slider-img/OurGallery/meal/meal (19).jpg';
import meal20 from '../assets/slider-img/OurGallery/meal/meal (20).jpg';
import meal21 from '../assets/slider-img/OurGallery/meal/meal (21).jpg';
import meal22 from '../assets/slider-img/OurGallery/meal/meal (22).jpg';
import meal23 from '../assets/slider-img/OurGallery/meal/meal (23).jpg';
import meal24 from '../assets/slider-img/OurGallery/meal/meal (24).jpg';
import meal25 from '../assets/slider-img/OurGallery/meal/meal (25).jpg';
import meal26 from '../assets/slider-img/OurGallery/meal/meal (26).jpg';
import meal27 from '../assets/slider-img/OurGallery/meal/meal (27).jpg';
import meal28 from '../assets/slider-img/OurGallery/meal/meal (28).jpg';


// Events

import events1 from '../assets/events/events 1.jpg';
import events2 from '../assets/events/events 2.jpg';
import events3 from '../assets/events/events 3.jpg';
import events5 from '../assets/events/events 5.jpg';
import events4 from '../assets/events/events 4.jpg';
import events6 from '../assets/events/events 6.jpg';











// eslint-disable-next-line
export default {
  bg,
  chef,
  C,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  sign,
  quote,
  chairman,

  mainbg,
  nightbg,

  // Menu Page 

  espatada,
  lambShank,
  lambRib,
  oxTail,
  filletOnTheBone,
  sashimi9ps,
  sushiSashimi,
  spicyTunaTemaki,
  chickenWings,
  sushiPlatter,
  salmonCali,
  deepFriedCali,

  // Gallery Page - bevs
  
  // Gallery Page - gallry

  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,

  // Gallery Page - meal

  meal1,
  meal2, 
  meal3,
  meal4, 
  meal5,
  meal6,
  meal7,
  meal8,
  meal9,
  meal10,
  meal11,
  meal12,
  meal13,
  meal14,
  meal15,
  meal16,
  meal17,
  meal18,
  meal19,
  meal20,
  meal21,
  meal22,
  meal23,
  meal24,
  meal25,
  meal26,
  meal27,
  meal28,

  // Events

  events1,
  events2,
  events3,
  events4,
  events5,
  events6,
  
};
