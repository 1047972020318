import { images } from '../constants'

const slides = [
        
        {
            src: images.events1,
            alt: 'Image 1'
        },
        {
            src: images.events2,
            alt: 'Image 2'
        },
        {
            src: images.events3,
            alt: 'Image 3'
        },
        {
            src: images.events4,
            alt: 'Image 4'
        },
        {
            src: images.events5,
            alt: 'Image 5'
        },
        {
            src: images.events6,
            alt: 'Image 6'
        },

    ]

export default slides;